<template>
  <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
    <div class="small-box bg-aqua-active pointer" v-on:click="$router.push('/reports/tasks')">
      <div class="inner">
        <h3>
          <small class="text-white" v-if="new_tasks_count > 0">+</small>{{ new_tasks_count }}
        </h3>
        <h5>Отчёты</h5>
      </div>
      <div class="icon">
        <i class="fas fa-chart-pie"></i>
      </div>
      <a class="small-box-footer" title="Перейти" style="padding-top: 6px; padding-bottom: 6px;">
        <i class="fa font-scale fa-arrow-circle-right"></i>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'reports-tasks-box',

  props: {
    new_tasks_count: {
      type: Number,
      required: true,
      default: 0,
    },
  }
}
</script>